import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import './css/style.css';

export const HeaderLanding = () => {

  // window.scrollTo(0,0);

  const butn = () => {

    let btn = document.querySelector('.mobile-nav-toggle');
    let navbar = document.querySelector('#navbar');
    navbar.classList.toggle('navbar-mobile')
    // btn.classList.toggle('bi-list');
    btn.classList.toggle('bi-x');



  }

  const tog =()=>{
    let btn = document.querySelector('.mobile-nav-toggle');
    let navbar = document.querySelector('#navbar');
    navbar.classList.remove('navbar-mobile')
    // navbar.classList.add('bi-list');
    btn.classList.remove('bi-x');
  }

  useEffect(() => {
    let selectHeader = document.querySelector('#header')
    window.onscroll = function () { scrollFunction2() };
    function scrollFunction2() {
      if (document.body.scrollTop > 100) {
        selectHeader.classList.add('header-scrolled')
      } else {
        selectHeader.classList.remove('header-scrolled')
      }

    }

    
  //   document.querySelector('#navbar ul li').onclick = ()=>{
  //     alert('hh')
  //     var allbtn = document.querySelectorAll('#navbar ul li')
  //     allbtn.forEach(e => {
  //         if(e!= this.innerHTML){
  //           alert(this.innerHTML)
  //         }
  //         console.log(e)
  //         console.log(this.innerHTML)
  //     });
  // }

  }, [])

 

  return (
    <div>



      {/* <!-- ======= Header ======= --> */}
      <header id="header" className="fixed-top" style={{ backgroundColor: '#6545ca' }}>
        <div className="container d-flex align-items-center">

          {/* <h1 className="logo me-auto"><a href="index.html"><span>In</span>TOGGLE</a></h1> */}
          {/* <!-- Uncomment below if you prefer to use an image logo --> */}
          <Link to="/" className="logo me-auto">
            <img src={require('./images/logo2.png')} alt="" className="img-fluid" />
          </Link>

          <nav id="navbar" className="navbar">
            <ul>
              <li onClick={tog}><a className="nav-link scrollto" href="#web-design">Web Designing</a></li>
              <li onClick={tog}><a className="nav-link scrollto" href="#smm">SMM</a></li>
              <li onClick={tog}><a className="nav-link scrollto" href="#app">App Development</a></li>
              <li onClick={tog}><a className="nav-link   scrollto" href="#logo">Logo Designing</a></li>
              <li onClick={tog}><a className="nav-link scrollto" href="#emailers">Emailers Designing</a></li>
              <li onClick={tog}><a className="nav-link scrollto" href="#blogging-site-optimization">Blogging Site Optimization</a></li>
              {/* <li className='d-block d-lg-none'><a className="getstarted scrollto  nav-link" href="/images/portfolio/intoggle-portfolio.pdf" download>Download Portfolio</a></li>
              <li className='d-lg-block d-none border-white'><a className="getstarted scrollto border-white nav-link" href='/images/portfolio/intoggle-portfolio.pdf' download>Download Portfolio</a></li> */}
            </ul>
            <i className="bi bi-list mobile-nav-toggle" onClick={butn}></i>
          </nav>
          {/* <!-- .navbar --> */}

        </div>
      </header>
      {/* <!-- End Header --> */}



    </div>
  )
}
