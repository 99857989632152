import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import axiosInstance from '../axios'
import { Footer } from './Footer'
import { Header } from './Header'
import { Helmet } from "react-helmet";
import Swal from 'sweetalert2'
import { ContactForm } from './ContactForm'
import { HeaderLanding } from './HeaderLanding'

export const LandingpageAds = () => {

    const Swal = require('sweetalert2')
    const [data, setdata] = useState({})

    const handle = (e) => {

        const newdata = { ...data }
        newdata[e.target.name] = e.target.value
        setdata(newdata)
        // console.log(newdata);
    }


    const submit = async (e) => {
        e.preventDefault();
        try {
            data.email = "webdev.shubham97@gmail.com";

            console.log("Name", data);
            const { results } = await axiosInstance.post(`https://emailapi.floorballharyana.com/api`, data


            )
            setdata({ Name: '', Email: '', Mobile: '', Industry: '', Message: '' });
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Thank you!!! Our Team will contact you within 24 hrs.',
                showConfirmButton: false,
                timer: 3000
            })
            // swal("Yeah", "Project Status updated", "success"); 
            // document.querySelector(".form").reset();

        }
        catch (error) {
            console.log(error);
        }
    }

    return (
        <div>

            <HeaderLanding />

            {/* <section className="contact_banner">

            </section> */}
            <article className="p-lg-5 p-2" id="fillform" >
                <section className="contact-us p-0 " style={{ marginTop: '70px' }}>
                    <div className="container pt-3 ">

                        <div className="row d-flex justify-content-center align-items-center">
                            <div className="col-md-6 pt-5 text-lg-start text-center">
                                <img src={require('./images/contact-here.webp')} width="80%" className="img-fluid" alt="" />

                            </div>
                            <div className="col-md-6 text-lg-start text-center">
                                <p className='text-white mb-1'>GET IN TOUCH</p>
                                <h3 className='text-white'>Simplify your online presence with <a href="www.intoggle.com" className='text-white text-decoration-none'>Intoggle</a></h3>
                                <form className="py-4 " id="form" onSubmit={(e) => submit(e)}>
                                    <div className="row mb-3">
                                        <div className="col">
                                            <input type="text" className="form-control" name="Name" value={data.Name} onChange={(e) => handle(e)} placeholder='Name' required />
                                        </div>
                                        <div className="col">
                                            <input type="email" className="form-control" name="Email" value={data.Email} onChange={(e) => handle(e)} placeholder='E-mail' required />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col">
                                            <input type="text" maxLength={10} minLength={10} className="form-control" name="Mobile" value={data.Mobile} onChange={(e) => handle(e)} placeholder='Phone Number' required />
                                        </div>
                                        <div className="col">
                                            <input type="text" className="form-control" name="Industry" value={data.Industry} onChange={(e) => handle(e)} placeholder='Business Name' required />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col">
                                            <textarea rows={3} className="form-control" name="Message" value={data.Message} onChange={(e) => handle(e)} placeholder='Your Message here' required></textarea>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col">
                                            <button type="submit" className="btn  col-12 bg-white ml-4">Submit</button>
                                        </div></div>



                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </article>

            <section className="servicepage">
                <div className="d-flex justify-content-center">
                    <div className="section-title text-center col-lg-6 text-muted">
                        <h2>Our <span> Services</span></h2>
                        <p>Looking to create a website or re-design an existing website?<br /> We are your one stop shop.</p>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row mt-4 " id="smm">
                        <div className="col-lg-9 p-5 row1">
                            <div className="row  align-items-center">
                                <div className="col-lg-7 pb-lg-0 pb-5">
                                    <div className="content-box text-start" >
                                        <h2><b>SOCIAL MEDIA MARKETING</b></h2>
                                        <p>
                                            Every business needs a strategy for marketing, and social media is one of the most effective ways. It's important to remember that a business has to use social media responsibly and never post anything they audience wouldn't want to see.
                                            Social media marketing is a powerful way to grow an audience, build brand awareness, and increase customer loyalty.
                                        </p>
                                        <a href="#fillform" className="btn mybtn  px-5">Get Service</a>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <img src={require('./images/row1.webp')} className="img-fluid rotate" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row  mt-4" id="web-design">
                        <div className="col-lg-9 offset-lg-3 p-5 row2">
                            <div className="row  align-items-center">
                                <div className="col-lg-5 pb-lg-0 pb-5">
                                    <img src={require('./images/row2.webp')} className="img-fluid rotate" alt="" />
                                </div>
                                <div className="col-lg-7">
                                    <div className="content-box text-start" >
                                        <h2><b>WEBSITE DESIGN & DEVELOPMENT</b></h2>
                                        <p>
                                            Web designing is an essential part of any business that wants to promote. It has the potential to increase your reach and can help you to stay in touch with your customers and employees.
                                            Website Development refers to the building, creating, and maintaining websites. It includes aspects such as web design, web publishing, web programming, and database management.
                                        </p>
                                        <a href="#fillform" className="btn mybtn  px-5">Get Service</a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="row  mt-4" id="app">
                        <div className="col-lg-9 p-5 row1">
                            <div className="row  align-items-center">
                                <div className="col-lg-7 pb-lg-0 pb-5">
                                    <div className="content-box text-start" >
                                        <h2><b>MOBILE APP DEVELOPMENT</b></h2>
                                        <p>
                                            <b>Intoggle</b> provide solutions for app development, creating a mobile identity for your company. In mobile app development, we have the knowledge and expertise to help you create an app that will empower your business and increase productivity.
                                        </p>
                                        <a href="#fillform" className="btn mybtn  px-5">Get Service</a>
                                    </div>
                                </div>
                                <div className="col-lg-5 ">
                                    <img src={require('./images/row3.webp')} className="img-fluid rotate" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row  mt-4" id="logo">
                        <div className="col-lg-9 offset-lg-3 p-5 row2">
                            <div className="row  align-items-center">
                                <div className="col-lg-5 pb-lg-0 pb-5">
                                    <img src={require('./images/row4.webp')} className="img-fluid rotate" alt="" />
                                </div>
                                <div className="col-lg-7">
                                    <div className="content-box text-start" >
                                        <h2><b>LOGO AND GRAPHICS DESIGN</b></h2>
                                        <p>
                                            A logo is a symbol or design used to identify a company or organization, as well as its products, services, employees, etc.
                                            we provide professional logo design and graphic design for various industries.
                                        </p>
                                        <a href="#fillform" className="btn mybtn  px-5">Get Service</a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="row mt-4 " id="emailers">
                        <div className="col-lg-9 p-5 row1">
                            <div className="row  align-items-center">
                                <div className="col-lg-7 pb-lg-0 pb-5" >
                                    <div className="content-box text-start" >
                                        <h2><b>EMAILERS DESIGN</b></h2>
                                        <p>
                                            Email design is the process of strategically designing and creating an email that resonates with your business's target audience, specifically your current email subscribers and customers.
                                            Design emailers template and address book with your own design and layout. Own the look of your emailer and make your business stand out.
                                        </p>
                                        <a href="#fillform" className="btn mybtn  px-5">Get Service</a>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <img src={require('./images/row5.webp')} className="img-fluid rotate" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row  mt-4" id="blogging-site-optimization">
                        <div className="col-lg-9 offset-lg-3 p-5 row2">
                            <div className="row  align-items-center">
                                <div className="col-lg-5 pb-lg-0 pb-5">
                                    <img src={require('./images/row6.webp')} className="img-fluid rotate" alt="" />
                                </div>
                                <div className="col-lg-7">
                                    <div className="content-box text-start" >
                                        <h2><b>BLOGGING SITE OPTIMIZATION</b></h2>
                                        <p>
                                            We provide high-quality blog site optimization services to help you create a professional, trustworthy site that will attract and engage the right audience.
                                            <b>Intoggle</b> helps you to provide high-quality user experience that makes a good impression on <b>Search Engines</b>.
                                        </p>
                                        <a href="#fillform" className="btn mybtn  px-5">Get Service</a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* Testimonial-- */}
            <div className="container">
                <div className="d-flex justify-content-center">
                    <div className="section-title text-center col-lg-6 text-muted">
                        <h2>Happy <span> Clients</span></h2>
                    </div>
                </div>
                <div id="carouselExampleIndicators" class="carousel slide container mb-5 bg-white" data-bs-ride="carousel">
                    {/* <div class="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div> */}
                    <div class="carousel-inner p-3 rounded" style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>
                        <div class="carousel-item active ">
                            <img src="https://floorballharyana.com/static/media/member2.71cacdbdc4aae020d8f4.jpg" style={{ width: '100px' }} class=" mx-auto rounded-circle d-block " alt="..." />
                            <div class="text-center pt-3 col-lg-9 mx-auto">
                                <p>
                                    Elementum tempus egestas sed sed risus pretium quam vulputate dignissim suspendisse in est ante in nibh mauris cursus mattis molestie a iaculis at erat pellentesque adipiscing commodo elit at imperdiet dui accumsan sit amet nulla
                                </p>
                                <blockquote class="blockquote mt-5">
                                    <footer class="blockquote-footer text-dark">Dr. Deepender<cite title="Source Title"><h5 >Haryana Floorball Association</h5></cite></footer>
                                </blockquote>
                            </div>
                        </div>
                        <div class="carousel-item  ">
                            <img src={require('./images/anilsastri.jfif')} style={{ width: '100px' }} class=" mx-auto rounded-circle d-block " alt="..." />
                            <div class="text-center pt-3 col-lg-9 mx-auto">
                                <p>
                                    Elementum tempus egestas sed sed risus pretium quam vulputate dignissim suspendisse in est ante in nibh mauris cursus mattis molestie a iaculis at erat pellentesque adipiscing commodo elit at imperdiet dui accumsan sit amet nulla
                                </p>
                                <blockquote class="blockquote mt-5">
                                    <footer class="blockquote-footer text-dark">Dr. Anil Sastri<cite title="Source Title"><h5 >Arhat Ayurvreda Science of Life</h5></cite></footer>
                                </blockquote>
                            </div>
                        </div>
                        <div class="carousel-item  ">
                            <img src={require('./images/arc.png')} style={{ width: '100px' }} class=" mx-auto rounded-circle d-block " alt="..." />
                            <div class="text-center pt-3 col-lg-9 mx-auto">
                                <p>
                                    Elementum tempus egestas sed sed risus pretium quam vulputate dignissim suspendisse in est ante in nibh mauris cursus mattis molestie a iaculis at erat pellentesque adipiscing commodo elit at imperdiet dui accumsan sit amet nulla
                                </p>
                                <blockquote class="blockquote mt-5">
                                    <footer class="blockquote-footer text-dark">Mr. Rahul Arya<cite title="Source Title"><h5 >ARC Solution</h5></cite></footer>
                                </blockquote>
                            </div>
                        </div>
                        <div class="carousel-item  ">
                            <img src={require('./images/devote.jpeg')} style={{ width: '100px' }} class=" mx-auto rounded-circle d-block " alt="..." />
                            <div class="text-center pt-3 col-lg-9 mx-auto">
                                <p>
                                    Elementum tempus egestas sed sed risus pretium quam vulputate dignissim suspendisse in est ante in nibh mauris cursus mattis molestie a iaculis at erat pellentesque adipiscing commodo elit at imperdiet dui accumsan sit amet nulla
                                </p>
                                <blockquote class="blockquote mt-5">
                                    <footer class="blockquote-footer text-dark">Mr. Shubham Kushvaha<cite title="Source Title"><h5 >ARC Solution</h5></cite></footer>
                                </blockquote>
                            </div>
                        </div>
                        <div class="carousel-item  ">
                            <img src={require('./images/motoring.png')} style={{ width: '100px' }} class=" mx-auto rounded-circle d-block " alt="..." />
                            <div class="text-center pt-3 col-lg-9 mx-auto">
                                <p>
                                    Elementum tempus egestas sed sed risus pretium quam vulputate dignissim suspendisse in est ante in nibh mauris cursus mattis molestie a iaculis at erat pellentesque adipiscing commodo elit at imperdiet dui accumsan sit amet nulla
                                </p>
                                <blockquote class="blockquote mt-5">
                                    <footer class="blockquote-footer text-dark">Mr. Sushant Khokar<cite title="Source Title"><h5 >21Motoring</h5></cite></footer>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                    
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                        <span aria-hidden="true" className="carousel-control-prev-icon ">
                            <i class="fa-solid fa-arrow-left-long text-dark"></i>
                        </span>

                        {/* <span class="visually-hidden">Previous</span> */}
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true">
                            <i class="fa-solid fa-arrow-right-long text-dark"></i>
                        </span>
                        {/* <span class="visually-hidden">Next</span> */}
                    </button>
                </div>
            </div>
            {/* Testimonial-- */}

            {/* Contact form-- start */}

            <ContactForm classElement="bg-white" />
            {/* Contact form-- end */}

            <Footer />

        </div>
    )
}
