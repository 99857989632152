import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Footer } from './Footer'
import { Header } from './Header'
import { Helmet } from "react-helmet";
import { ContactForm } from './ContactForm';

export const Project = () => {
  return (
    <div>

      <Helmet>
        <meta charSet="utf-8" />
        <title>Project | quality delivered</title>

        <meta name='description' content='Best Website Development Services in Rohtak and (All over Haryana). Our aim is always to provide the best services with 100% satisfaction for our clients. Intoggle provides 360 solutions online all over Haryana.' />

        <meta name="keywords" content="Best Digital Marketing Company in Rohtak, Digital marketing companies in Rohtak, Digital Marketing Agency, Best Website design company in rohtak" />
      </Helmet>

      <Header />

      {/* Baner start--- */}
      <section className="about d-flex align-items-center">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-6">
              <div className="under-heading text-center text-white">
                <h3>QUALITY DELIVERED</h3>
              </div>
              <div className="aboutbox text-center text-white">
                <h2>We are your one stop shop.</h2>
                {/* <h2>Whatever you've got in mind<br /> we've got inside.</h2> */}
                <Link to="/contact" className='btn shub-btn'>Contact us</Link>
              </div>
            </div>

            {/* <div className="col-lg-6"></div> */}
          </div>
        </div>
      </section>
      {/* Baner end--- */}


      <section className="projects">
        <div className="d-flex justify-content-center">
          <div className="section-title text-center col-lg-6 text-muted">
            <h2>Our <span> Projects</span></h2>
            <p>We pride ourselves on collaborating with organizations to deliver memorable projects</p>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 pt-5">
              <div className="projectimg card">
                <img  src={require('./images/projects/1.jpg')} class="card-img-top rounded " alt="..." />
              </div>
            </div>
            <div className="col-lg-4 pt-5">
              <div className="projectimg card">
                <img  src={require('./images/projects/2.jpg')} class="card-img-top rounded " alt="..." />
              </div>
            </div>
            <div className="col-lg-4 pt-5">
              <div className="projectimg card">
                <img  src={require('./images/projects/3.jpg')} class="card-img-top rounded " alt="..." />
              </div>
            </div>
            <div className="col-lg-4 pt-5">
              <div className="projectimg card">
                <img  src={require('./images/projects/4.jpg')} class="card-img-top rounded " alt="..." />
              </div>
            </div>
            <div className="col-lg-4 pt-5">
              <div className="projectimg card">
                <img  src={require('./images/projects/5.jpg')} class="card-img-top rounded " alt="..." />
              </div>
            </div>
            <div className="col-lg-4 pt-5">
              <div className="projectimg card">
                <img  src={require('./images/projects/6.jpg')} class="card-img-top rounded " alt="..." />
              </div>
            </div>
            <div className="col-lg-4 pt-5">
              <div className="projectimg card">
                <img  src={require('./images/projects/7.jpg')} class="card-img-top rounded " alt="..." />
              </div>
            </div>
 
          </div>
        </div>
      </section>


      <ContactForm classElement="bg-white"/>

      <Footer />

    </div>
  )
}
