import React from "react";
import { Helmet } from "react-helmet";
import { Header } from "./Header";
import { Footer } from "./Footer";

const TermsConditions = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Terms & Conditions | Intoggle</title>
        <meta
          name="description"
          content="Intoggle is an web designing and digital marketing agency that offers professional website design, search engine optimization, Social Media Marketing services in India."
        />
        <meta
          name="keywords"
          content="Digital marketing companies in Rohtak, Best Digital marketing company in Rohtak, Digital marketing services in Rohtak, Web design & development company in Rohtak, Social media marketing company in Rohatk, Social media marketing services in Rohtak, e-commerce website design in rohtak"
        />
      </Helmet>
      <Header />
      <section className="terms-breadcrumb-container">
        <div className="terms-breadcrumb text-center">
          <h1>Terms & Conditions</h1>
        </div>
      </section>
      <section className="terms-content container pt-0">
        <p>
          This page states the Terms and Conditions under which you (Visitor)
          may visit this website (“intoggle.com”). Please read this page
          carefully. If you do not accept the Terms and Conditions stated here,
          we would request you to exit this site. The business, any of its
          business divisions and / or its subsidiaries, associate companies or
          subsidiaries to subsidiaries or such other investment companies (in
          India or abroad) reserve their respective rights to revise these Terms
          and Conditions at any time by updating this posting. You should visit
          this page periodically to re-appraise yourself of the Terms and
          Conditions, because they are binding on all users of this Website.
          During the course you may create a project as mention in the lectures.
          We will be showcasing those projects on our website as Learners
          project.
        </p>
        <strong>Liability</strong>
        <p>
          User agrees that neither Company nor its group companies, directors,
          officers or employee shall be liable for any direct or/and indirect
          or/and incidental or/and special or/and consequential or/and exemplary
          damages, resulting from the use or/and the inability to use the
          service or/and for cost of procurement of substitute goods or/and
          services or resulting from any goods or/and data or/and information
          or/and services purchased or/and obtained or/and messages received
          or/and transactions entered into through or/and from the service
          or/and resulting from unauthorized access to or/and alteration of
          user's transmissions or/and data or/and arising from any other matter
          relating to the service, including but not limited to, damages for
          loss of profits or/and use or/and data or other intangible, even if
          Company has been advised of the possibility of such damages.
        </p>
        <strong>Cancellation Policy</strong>
        <p>
          For Cancellations please contact the us via contact us link. Requests
          received later than 10 business days prior to the end of the current
          service period will be treated as cancellation of services for the
          next service period.
        </p>
        <strong>Refund Policy</strong>
        <p>
          We will try our best to create the suitable design concepts for our
          clients. In case any client is not completely satisfied with our
          products we can provide a refund. If paid by credit card, refunds will
          be issued to the original credit card provided at the time of purchase
          and in case of payment gateway name payments refund will be made to
          the same account.
        </p>
      </section>

      <Footer />
    </div>
  );
};

export default TermsConditions;
