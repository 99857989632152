import React from "react";
import { Helmet } from "react-helmet";
import { Header } from "./Header";
import { Footer } from "./Footer";

const PrivacyPolicy = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Privacy Policy | Intoggle</title>
        <meta
          name="description"
          content="Intoggle is an web designing and digital marketing agency that offers professional website design, search engine optimization, Social Media Marketing services in India."
        />
        <meta
          name="keywords"
          content="Digital marketing companies in Rohtak, Best Digital marketing company in Rohtak, Digital marketing services in Rohtak, Web design & development company in Rohtak, Social media marketing company in Rohatk, Social media marketing services in Rohtak, e-commerce website design in rohtak"
        />
      </Helmet>
      <Header />
      <section className="terms-breadcrumb-container">
        <div className="terms-breadcrumb text-center">
          <h1>Privacy Policy</h1>
        </div>
      </section>
      <section className="terms-content container pt-0">
        <p>
          This Privacy Policy is an electronic record in the form of an
          electronic contract formed under the information Technology Act, 2000
          and the rules made thereunder and the amended provisions pertaining to
          electronic documents / records in various statutes as amended by the
          information Technology Act, 2000. This Privacy Policy does not require
          any physical, electronic or digital signature.
        </p>
        <strong>USER INFORMATION</strong>
        <p>
          To avail certain services on our Websites, users are required to
          provide certain information for the registration process namely: - a)
          your name, b) email address, c) sex, d) age, e) PIN code, f) credit
          card or debit card details g) medical records and history h) sexual
          orientation, i) biometric information, j) password etc., and / or your
          occupation, interests, and the like. The Information as supplied by
          the users enables us to improve our sites and provide you the most
          user-friendly experience. All required information is service
          dependent and we may use the above said user information to, maintain,
          protect, and improve its services (including advertising services) and
          for developing new services Such information will not be considered as
          sensitive if it is freely available and accessible in the public
          domain or is furnished under the Right to Information Act, 2005 or any
          other law for the time being in force.
        </p>
        <strong>COOKIES</strong>
        <p>
          To improve the responsiveness of the sites for our users, we may use
          "cookies", or similar electronic tools to collect information to
          assign each visitor a unique, random number as a User Identification
          (User ID) to understand the user's individual interests using the
          Identified Computer. Unless you voluntarily identify yourself (through
          registration, for example), we will have no way of knowing who you
          are, even if we assign a cookie to your computer. The only personal
          information a cookie can contain is information you supply (an example
          of this is when you ask for our Personalised Horoscope). A cookie
          cannot read data off your hard drive. Our advertisers may also assign
          their own cookies to your browser (if you click on their ads), a
          process that we do not control.
        </p>
     
      </section>

      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
