import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import axiosInstance from '../axios'
import { Footer } from './Footer'
import { Header } from './Header'
import { Helmet } from "react-helmet";
import Swal from 'sweetalert2'

export const Contact = () => {

    const Swal = require('sweetalert2')
    const [data, setdata] = useState({})

    const handle = (e) => {

        const newdata = { ...data }
        newdata[e.target.name] = e.target.value
        setdata(newdata)
        // console.log(newdata);
    }


    const submit = async (e) => {
        e.preventDefault();
        try {
            data.email = "webdev.shubham97@gmail.com";

            console.log("Name", data);
            const { results } = await axiosInstance.post(`https://emailapi.floorballharyana.com/api`, data


            )
            setdata({Name: '', Email: '', Mobile: '', Industry: '', Message: ''});
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Thank you!!! Our Team will contact you within 24 hrs.',
                showConfirmButton: false,
                timer: 3000
              })
            // swal("Yeah", "Project Status updated", "success"); 
            // document.querySelector(".form").reset();

        }
        catch (error) {
            console.log(error);
        }
    }

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Contact Us | Contact us and bring your business online. </title>

                <meta name='description' content='Searching for web design & Development Company in Rohtak? Contact us and create your business online with Intoggle.' />

                <meta name="keywords" content="Digital marketing companies in Rohtak, Best Digital marketing company in Rohtak, Digital marketing services in Rohtak, Web design & development company in Rohtak, Social media marketing company in Rohatk, Social media marketing services in Rohtak." />
            </Helmet>

            <Header />

            {/* <section className="contact_banner">

            </section> */}
            <article className="p-lg-5 p-2" >
                <section className="contact-us p-0 " style={{ marginTop: '70px' }}>
                    <div className="container pt-3 ">

                        <div className="row d-flex justify-content-center align-items-center">
                            <div className="col-md-6 pt-5 text-lg-start text-center">
                                <img src={require('./images/contact-here.webp')} width="80%" className="img-fluid" alt="" />

                            </div>
                            <div className="col-md-6 text-lg-start text-center">
                                <p className='text-white mb-1'>GET IN TOUCH</p>
                                <h3 className='text-white'>Simplify your online presence with <a href="www.intoggle.com" className='text-white text-decoration-none'>Intoggle</a></h3>
                                <form className="py-4 " id="form" onSubmit={(e) => submit(e)}>
                                    <div className="row mb-3">
                                        <div className="col">
                                            <input type="text" className="form-control" name="Name" value={data.Name} onChange={(e) => handle(e)} placeholder='Name' required />
                                        </div>
                                        <div className="col">
                                            <input type="email" className="form-control" name="Email" value={data.Email} onChange={(e) => handle(e)} placeholder='E-mail' required />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col">
                                            <input type="text" maxLength={10} minLength={10} className="form-control" name="Mobile" value={data.Mobile} onChange={(e) => handle(e)} placeholder='Phone Number' required />
                                        </div>
                                        <div className="col">
                                            <input type="text" className="form-control" name="Industry" value={data.Industry} onChange={(e) => handle(e)} placeholder='Business Name' required />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col">
                                            <textarea rows={3} className="form-control" name="Message" value={data.Message} onChange={(e) => handle(e)} placeholder='Your Message here' required></textarea>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col">
                                            <button type="submit" className="btn  col-12 bg-white ml-4">Submit</button>
                                        </div></div>



                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </article>

            {/* =========== Call to Action ============== */}
            <section className="ctaabout py-5 mt-5">
                <div class="cta1">
                    <div className="container py-lg-5 ">
                        <div className="text-center text-white mb-4 mb-lg-0">
                            {/* <b>WORK FOR ANY INDUSTRY</b> */}
                            <b>You have idea, We have solution</b>
                            <h2><b>Let's start a new project together</b></h2>
                        </div>
                        <div className="d-flex justify-content-center">
                            <a className="btn mx-4 text-white " href="tel:7988829912">Call Now</a>
                            <Link className="btn mx-4 bg-white text-dark " to="/contact">Contact us</Link>
                        </div>

                    </div>
                </div>
            </section>
            {/* =========== Call to Action ============== */}


            <section id="contact" className="d-none">
                <div class="contact-box">
                    <div class="contact-links">
                        <h2>CONTACT</h2>
                        <div class="links">
                            <div class="link">
                                <a><img src="https://i.postimg.cc/m2mg2Hjm/linkedin.png" alt="linkedin" /></a>
                            </div>
                            <div class="link">
                                <a><img src="https://i.postimg.cc/YCV2QBJg/github.png" alt="github" /></a>
                            </div>
                            <div class="link">
                                <a><img src="https://i.postimg.cc/W4Znvrry/codepen.png" alt="codepen" /></a>
                            </div>
                            <div class="link">
                                <a><img src="https://i.postimg.cc/NjLfyjPB/email.png" alt="email" /></a>
                            </div>
                        </div>
                    </div>
                    <div class="contact-form-wrapper">
                        <form action="">
                            <div className="row">
                                <div class="mb-3 col">
                                    <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Name" />
                                </div>
                                <div class="mb-3 col">
                                    <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Number" />
                                </div>
                            </div>
                            <div className="row">
                                <div class="mb-3 col">
                                    <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="Email address" />
                                </div>
                            </div>
                            <div className="row">
                                <div class="mb-3 col">
                                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder='Message'></textarea>
                                </div>
                            </div>
                            <button className="btn bg-white col-12">Submit</button>
                        </form>
                    </div>
                </div>
            </section>



            <Footer />

        </div>
    )
}
