import React from 'react'
import { Footer } from './Footer'
import { Header } from './Header'
import { Helmet } from "react-helmet";
import { ContactForm } from './ContactForm';
import { Link } from 'react-router-dom';


export const Services = () => {
    return (
        <div>
             <Helmet>
                <meta charSet="utf-8" />
                <title>Intoggle Services | Website Development, Logo designs company in rohtak</title>

                <meta name='description' content='Intoggle is an IT and Marketing solutions provider whether you need Website Design, search engine optimization, digital marketing, blogging site optimization.' />

                <meta name="keywords" content="Web Development Company in Rohtak, Best web development firm, Best web development company website, Website designing & development company in Haryana" />
            </Helmet>

            <Header />

            {/* Baner start--- */}
            <section className="services d-flex align-items-center">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-6">
                            <div className="under-heading text-center text-white">
                                <h3 className='text-purple'>SERVICES</h3>
                            </div>
                            <div className="aboutbox text-center">
                                <h2>It's Time To Convert Your Idea<br /> Into Business</h2>
                                <Link to="/contact" className='btn shub-btn'>Contact us</Link>
                            </div>
                        </div>

                        {/* <div className="col-lg-6"></div> */}
                    </div>
                </div>
            </section>
            {/* Baner end--- */}

            <section className="servicepage">
                <div className="d-flex justify-content-center">
                    <div className="section-title text-center col-lg-6 text-muted">
                        <h2>Our <span> Services</span></h2>
                        <p>Looking to create a website or re-design an existing website?<br /> We are your one stop shop.</p>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row mt-4 ">
                        <div className="col-lg-9 p-5 row1">
                            <div className="row  align-items-center">
                                <div className="col-lg-7 pb-lg-0 pb-5">
                                    <div className="content-box text-start" >
                                        <h2><b>SOCIAL MEDIA MARKETING</b></h2>
                                        <p>
                                            Every business needs a strategy for marketing, and social media is one of the most effective ways. It's important to remember that a business has to use social media responsibly and never post anything they audience wouldn't want to see.
                                            Social media marketing is a powerful way to grow an audience, build brand awareness, and increase customer loyalty.
                                        </p>
                                        <a href="tel:7988829912" className="btn mybtn  px-5">Get Service</a>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <img src={require('./images/row1.webp')} className="img-fluid rotate" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row  mt-4">
                        <div className="col-lg-9 offset-lg-3 p-5 row2">
                            <div className="row  align-items-center">
                                <div className="col-lg-5 pb-lg-0 pb-5">
                                    <img src={require('./images/row2.webp')} className="img-fluid rotate" alt="" />
                                </div>
                                <div className="col-lg-7">
                                    <div className="content-box text-start" >
                                        <h2><b>WEBSITE DESIGN & DEVELOPMENT</b></h2>
                                        <p>
                                            Web designing is an essential part of any business that wants to promote. It has the potential to increase your reach and can help you to stay in touch with your customers and employees.
                                            Website Development refers to the building, creating, and maintaining websites. It includes aspects such as web design, web publishing, web programming, and database management.
                                        </p>
                                        <a href="tel:7988829912" className="btn mybtn  px-5">Get Service</a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="row  mt-4">
                        <div className="col-lg-9 p-5 row1">
                            <div className="row  align-items-center">
                                <div className="col-lg-7 pb-lg-0 pb-5">
                                    <div className="content-box text-start" >
                                        <h2><b>MOBILE APP DEVELOPMENT</b></h2>
                                        <p>
                                            <b>Intoggle</b> provide solutions for app development, creating a mobile identity for your company. In mobile app development, we have the knowledge and expertise to help you create an app that will empower your business and increase productivity.
                                        </p>
                                        <a href="tel:7988829912" className="btn mybtn  px-5">Get Service</a>
                                    </div>
                                </div>
                                <div className="col-lg-5 ">
                                    <img src={require('./images/row3.webp')} className="img-fluid rotate" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row  mt-4">
                        <div className="col-lg-9 offset-lg-3 p-5 row2">
                            <div className="row  align-items-center">
                                <div className="col-lg-5 pb-lg-0 pb-5">
                                    <img src={require('./images/row4.webp')} className="img-fluid rotate" alt="" />
                                </div>
                                <div className="col-lg-7">
                                    <div className="content-box text-start" >
                                        <h2><b>LOGO AND GRAPHICS DESIGN</b></h2>
                                        <p>
                                            A logo is a symbol or design used to identify a company or organization, as well as its products, services, employees, etc.
                                            we provide professional logo design and graphic design for various industries.
                                        </p>
                                        <a href="tel:7988829912" className="btn mybtn  px-5">Get Service</a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="row mt-4 ">
                        <div className="col-lg-9 p-5 row1">
                            <div className="row  align-items-center">
                                <div className="col-lg-7 pb-lg-0 pb-5" >
                                    <div className="content-box text-start" >
                                        <h2><b>EMAILERS DESIGN</b></h2>
                                        <p>
                                            Email design is the process of strategically designing and creating an email that resonates with your business's target audience, specifically your current email subscribers and customers.
                                            Design emailers template and address book with your own design and layout. Own the look of your emailer and make your business stand out.
                                        </p>
                                        <a href="tel:7988829912" className="btn mybtn  px-5">Get Service</a>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <img src={require('./images/row5.webp')} className="img-fluid rotate" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row  mt-4">
                        <div className="col-lg-9 offset-lg-3 p-5 row2">
                            <div className="row  align-items-center">
                                <div className="col-lg-5 pb-lg-0 pb-5">
                                    <img src={require('./images/row6.webp')} className="img-fluid rotate" alt="" />
                                </div>
                                <div className="col-lg-7">
                                    <div className="content-box text-start" >
                                        <h2><b>BLOGGING SITE OPTIMIZATION</b></h2>
                                        <p>
                                            We provide high-quality blog site optimization services to help you create a professional, trustworthy site that will attract and engage the right audience.
                                            <b>Intoggle</b> helps you to provide high-quality user experience that makes a good impression on <b>Search Engines</b>.
                                        </p>
                                        <a href="tel:7988829912" className="btn mybtn  px-5">Get Service</a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Contact form-- start */}

            <ContactForm classElement="bg-white"/>
            {/* Contact form-- end */}


            <Footer />

        </div>
    )
}
