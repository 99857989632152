import React from "react";
import { Link } from "react-router-dom";

export const Footer = () => {
  const top = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <div class="footer p-5">
        <div class="container  text-center text-md-start text-white">
          <div class="row justify-content-center align-items-center">
            <div class="col-md-4 col-12 col-xl-3 mx-auto mb-4">
              <div class="logo mb-2">
                <a href="index.html" className="logo me-auto">
                  <img
                    src={require("./images/logo1.png")}
                    width="25%"
                    alt=""
                    className="img-fluid"
                  />
                </a>
              </div>
              {/* <hr class="mb-4 mt-0 d-inline-block mx-auto" style={{ width: '60px', background: 'rgb(236, 102, 31)', height: ' 2px' }} /> */}
              <p>
                Team InToggle is a group of professional, highly skilled and
                diverse individuals with strong work ethics, who have come
                together to create a team that embodies diversity.{" "}
              </p>
            </div>
            <div class="col-lg-4 col-12">
              <h6 class="text-uppercase fw-bold text-dark">Contact</h6>
              <hr
                class="mb-4 mt-0 d-inline-block mx-auto"
                style={{
                  width: "60px",
                  background: "rgb(124, 77, 255)",
                  height: "2px",
                }}
              />
              <p>
                <i class="fas fa-home mr-3">&nbsp;&nbsp;</i>Sector-20, Noida -
                201301
              </p>
              <p>
                <i class="fas fa-envelope mr-3">&nbsp;&nbsp;</i>
                contact.intoggle@gmail.com
              </p>
              <p>
                <i class="fas fa-phone mr-3">&nbsp;&nbsp;</i>+91-7988829912
              </p>
            </div>
            <div class="col-lg-4 col-12">
              {/* <h6 class="text-uppercase fw-bold text-dark">Contact</h6> */}
              {/* <hr class="mb-4 mt-0 d-inline-block mx-auto" style={{ width: '60px', background: 'rgb(124, 77, 255)', height: '2px' }} /> */}
              {/* <div class="input-group mb-3 flex-column">
                                <input class="form-control text-dark border w-100 mb-2" type="text" placeholder="Default input" aria-label="default input example"  />
                                <a href="" className="btn" style={{ backgroundColor: 'var(--color1)', color: '#fff' }}> Submit </a>
                            </div> */}
              <h3 className="text-dark mx-2">
                <b>Follow us</b>
              </h3>
              <div className="icons d-flex  justify-content-lg-start justify-content-center ">
                <a href="https://www.facebook.com/intoggle" target="_blank">
                  <img
                    className="mx-2"
                    src={require("./images/facebook.png")}
                    alt=""
                  />
                </a>
                <a href="https://www.instagram.com/in_toggle/" target="_blank">
                  <img
                    className="mx-2"
                    src={require("./images/instagram.png")}
                    alt=""
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/intoggle"
                  target="_blank"
                >
                  <img
                    className="mx-2"
                    src={require("./images/linkedin.png")}
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Whatsapp start--- */}
      <div id="whatsapp" class="whatsapp">
        <a
          href="https://wame.pro/532gs8"
          target="_blank"
          title="Whatsapp"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
        >
          <i class="fab fa-whatsapp">&nbsp; </i>
          Whatsapp
        </a>
      </div>
      {/* whatsapp end--- */}
      {/* Top Start---- */}
      <div id="top" className="top" onClick={top}>
        <a
          target="_blank"
          title="Whatsapp"
          data-bs-toggle="Go To Top"
          data-bs-placement="top"
        >
          <i class="fa-sharp fa-solid fa-arrow-up"></i>
        </a>
      </div>
      {/* Top End---- */}
      <div
        className="p-3 text-center text-white"
        style={{ backgroundColor: "#6545ca" }}
      >
        <div>
          <div className="d-flex justify-content-center">
            <Link
              className="btn-terms text-white text-decoration-none"
              to="/terms-and-conditions"
            >
              Terms & Conditions
            </Link>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Link
              className="btn-terms text-white text-decoration-none"
              to="/privacy-policy"
            >
              Privacy Policy
            </Link>
          </div>
        </div>
        <small>
          &#169;Copyright 2024{" "}
          <a
            className="text-white text-decoration-none"
            href="https://www.intoggle.com/"
          >
            Intoggle
          </a>
        </small>
      </div>
    </div>
  );
};
