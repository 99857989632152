import React from 'react'
import { useState } from 'react'
import axiosInstance from '../axios'
const Swal = require('sweetalert2')

export const ContactForm = (classElement) => {

    const [data, setdata] = useState({})

    const handle = (e) => {

        const newdata = { ...data }
        newdata[e.target.name] = e.target.value
        setdata(newdata)
        // console.log(newdata);
    }


    const submit = async (e) => {
        e.preventDefault();
        try {
            data.email = "webdev.shubham97@gmail.com";

            console.log("Name", data);
            const { results } = await axiosInstance.post(`https://emailapi.floorballharyana.com/api`, data


            )
            // alert('Form Succes');
            setdata({Name: '', Email: '', Mobile: ''});
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Thank you!!! Our Team will contact you within 24 hrs.',
                showConfirmButton: false,
                timer: 3000
              })
            // console.log(data);
            // swal("Yeah", "Project Status updated", "success"); 

        }
        catch (error) {
            console.log(error);
        }
    }
    // console.log(classElement);

    return (
        <div>

            <section className={`${classElement.classElement} newform pt-5 pb-0`} >
                <div className="formbox pt-5" style={{ backgroundColor: '#6545ca' }}>
                    <div className="container">
                        <div className="row align-items-center flex-lg-row flex-column-reverse ">
                            <div className="col-lg-7 p-lg-0 py-5 ">
                                <div className="text-white text-lg-start text-center">
                                    <h3>
                                        <b>Want to bring your business online.</b>
                                    </h3>
                                    <h2>
                                        <b>
                                         Fill the form below and connect with our experts.
                                        </b>
                                    </h2>
                                </div>
                                <div>
                                    <form className="py-4 " id="form" onSubmit={(e) => submit(e)}>
                                        <div className="row mt-5 mb-3">
                                            <div className="col">
                                                <input type="text" className="form-control" name="Name" value={data.Name} onChange={(e) => handle(e)} placeholder='Name' required />
                                            </div>
                                            <div className="col">
                                                <input type="text" className="form-control" name="Mobile" value={data.Mobile} onChange={(e) => handle(e)} placeholder='Number' required />
                                            </div>
                                            <div className="col">
                                                <input type="email" className="form-control" name="Email" value={data.Email} onChange={(e) => handle(e)} placeholder='E-mail' required />
                                            </div>
                                        </div>
                                        <div>
                                            <button  className="btn bg-white px-5 col-12">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-4 offset-md-1">
                                <div className="sidebox bg-white px-lg-4 py-lg-5 p-4" style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', borderRadius: '10px' }}>
                                    <img src={require('./images/logo1.png')} width="20%" alt="" />
                                    <div className='innerbox mt-3'>
                                        <p>
                                            <b>T.Harv Eker </b>once said<br />
                                            <i>"Helping people grow and achieve their dreams is the fastest route to success; both theirs and yours." </i>
                                        </p>
                                        <p className='mb-0'><b>Shubham Tiwari</b></p>
                                        <small>Founder & CEO, Intoggle</small>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}
