import React from "react";
import "./css/style.css";
import { Header } from "./Header";
import emailjs from "emailjs-com";
import swal from "sweetalert";
import { Footer } from "./Footer";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ContactForm } from "./ContactForm";
import BrandsSlider from "./BrandsSlider";

export const Home = () => {
  // const sendEmail = (e) => {
  //     e.preventDefault();
  //     emailjs.sendForm("service_9905mum", "template_y1mod4g", e.target, "2qE402HTWXBaFWQbm").then(res => {
  //         // console.log(res);
  //         swal("Good job!", "You clicked the button!", "success");
  //         e.target.reset();
  //     }).catch(err =>
  //         console.log(err)
  //     );
  // }

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Intoggle | Let's Switch to Creative Part</title>
        <meta
          name="description"
          content="Intoggle is an web designing and digital marketing agency that offers professional website design, search engine optimization, Social Media Marketing services in India."
        />
        <meta
          name="keywords"
          content="Digital marketing companies in Rohtak, Best Digital marketing company in Rohtak, Digital marketing services in Rohtak, Web design & development company in Rohtak, Social media marketing company in Rohatk, Social media marketing services in Rohtak, e-commerce website design in rohtak"
        />
      </Helmet>

      <Header />

      {/* <!-- ======= Hero Section ======= --> */}
      <section id="hero" className="d-flex align-items-center">
        <div className="container">
          <div className="row flex-lg-row flex-column-reverse">
            <div
              className="col-lg-5 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <h1>Let’s Switch to Creative Part</h1>
              <p className="text-white">
                We excel in crafting creative strategies for Social Media and
                Designing user-friendly websites. Your partner for all your web
                needs for today and tomorrow.
              </p>

              <div className="d-flex justify-content-center justify-content-lg-start">
                <a href="#why-us" className="btn-get-started scrollto nav-link">
                  <i class="fa-solid fa-angles-down"></i>
                </a>
              </div>
            </div>
            <div
              className="col-lg-7 order-1 order-lg-2 hero-img"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <div className="bannerimg">
                <img
                  src={require("./images/bannertech-min.png")}
                  className="img-fluid "
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Hero --> */}

      {/* <!-- ======= Services ======= --> */}
      <section
        id="why-us"
        className="why-us py-3"
        style={{ backgroundColor: "rgba(193, 180, 232,0.1)" }}
      >
        <div className="container py-5">
          <div className="d-flex justify-content-center">
            <div className="section-title text-center col-lg-6 text-muted">
              <h2>
                Our <span> Services</span>
              </h2>
              <p>
                Looking for a website or want to re-design an existing one?{" "}
                <br />
                We are your one stop shop.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="box hoverbox1 text-start">
                <div>
                  <span>
                    <i className="fa-solid fa-pen-ruler"></i>
                  </span>
                  {/* <span><i className="fa-solid fa-box"></i></span> */}
                  <h4>SOCIAL MEDIA MARKETING </h4>
                </div>

                <div>
                  <p className="mb-2">
                    Social media marketing is for business promotion, customer
                    service, advertising and other purposes.
                  </p>
                </div>
                <div>
                  <Link
                    to="/services"
                    className="btn mybtn text-white px-3 py-1"
                  >
                    View More
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mt-4 mt-lg-0">
              <div className="box hoverbox3 text-start">
                <div>
                  <span>
                    <i className="fa-brands fa-css3-alt"></i>
                  </span>
                  {/* <span><i className="fa-solid fa-coins"></i></span> */}
                  <h4>WEB DESIGNING</h4>
                </div>

                <div>
                  <p className="mb-2">
                    Web designing is an essential part of any business that
                    wants to promote their products or services online. It helps
                    in creating an Image and Online Presence for your company.{" "}
                  </p>
                </div>
                <div>
                  <Link
                    to="/services"
                    className="btn mybtn text-white px-3 py-1"
                  >
                    View More
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mt-4 mt-lg-0">
              <div className="box hoverbox2 text-start">
                <div>
                  <span>
                    <i class="fa-brands fa-wordpress"></i>
                  </span>
                  <h4>BLOGGING SITE OPTIMIZATION</h4>
                </div>

                <div>
                  <p className="mb-2">
                    We provide high-quality blog site optimization services to
                    help you create a professional, trustworthy site that will
                    attract and engage the right audience.
                  </p>
                </div>
                <div>
                  <Link
                    to="/services"
                    className="btn mybtn text-white px-3 py-1"
                  >
                    View More
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mt-4 mt-lg-0 py-lg-4">
              <div className="box hoverbox4 text-start">
                <div>
                  <span>
                    <i className="fa-solid fa-rectangle-ad"></i>
                  </span>
                  <h4>LOGO DESIGN</h4>
                </div>
                <div>
                  <p className="mb-2">
                    A logo is a symbol or design used to identify a company or
                    organization, as well as its products, services, employees,
                    etc. Logo helps you create your Brand Identity.
                  </p>
                </div>
                <div>
                  <Link
                    to="/services"
                    className="btn mybtn text-white px-3 py-1"
                  >
                    View More
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mt-4 mt-lg-0 py-lg-4">
              <div className="box hoverbox5 text-start">
                <div>
                  <span>
                    <i class="fa-solid fa-mobile-screen-button"></i>
                  </span>
                  <h4>APP DEVELOPEMENT</h4>
                </div>
                <div>
                  <p className="mb-2">
                    We provide solutions related to App Development and helps
                    you create an app that will empower your business and
                    Increase Productivity.
                  </p>
                </div>
                <div>
                  <Link
                    to="/services"
                    className="btn mybtn text-white px-3 py-1"
                  >
                    View More
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mt-4 mt-lg-0 py-lg-4">
              <div className="box hoverbox6 text-start">
                <div>
                  <span>
                    <i className="fa-solid fa-envelopes-bulk"></i>
                  </span>
                  <h4>E-MAILER DESIGNS </h4>
                </div>
                <div>
                  <p className="mb-2">
                    Email design is the process of strategically designing and
                    creating an email that resonates with your business's target
                    audience, specifically your current email subscribers and
                    customers.
                  </p>
                </div>
                <div>
                  <Link
                    to="/services"
                    className="btn mybtn text-white px-3 py-1"
                  >
                    View More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Services --> */}

      {/* =========== Why Choose us ============== */}
      {/* <section className='vision_mission'>
                <div className="container">
                    <div className="row py-5 justify-content-center align-items-center">
                        <div className="col-lg-6">
                            <div className="text-center">
                                <h4><b>dummy dummy dummby</b></h4>
                                <p>
                                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Fuga odio neque iure deserunt repudiandae aliquid maxime eos alias. Excepturi pariatur voluptates sit earum quos culpa, explicabo et repudiandae nihil? Reprehenderit.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="row justify-content-center">
                                <div className="col-6 text-center py-3" id='right' style={{ borderRadius: '50px 50px', boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>
                                    <img src={require('./images/activity.png')} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row py-5 justify-content-center align-items-center">

                        <div className="col-lg-6">
                            <div className="row justify-content-center">
                                <div className="col-6 text-center py-3" id='right' style={{ borderRadius: '50px 50px', boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>
                                    <img src={require('./images/launch.png')} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="text-center">
                                <h4><b>dummy dummy dummby</b></h4>
                                <p>
                                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Fuga odio neque iure deserunt repudiandae aliquid maxime eos alias. Excepturi pariatur voluptates sit earum quos culpa, explicabo et repudiandae nihil? Reprehenderit.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
      {/* =========== Services ============== */}

      {/* =========== Who We Are ============== */}
      <section className="we_are p-lg-0 p-3">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-4">
              <div className="text-start text-white">
                <small>
                  <b>Who We Are</b>
                </small>
                <h2>
                  <b>
                    WE OFFER QUICK & POWERFULL <br />
                    SOLUTIONS FOR BUSINESSES
                  </b>
                </h2>

                <p>
                  Intoggle is an Best Web Designing and Digital Marketing
                  company in Rohtak, (Haryana) that offers Professional and
                  Digital Services in all over India now. We provide affordable
                  website design and development, logo design, search engine
                  optimization (SEO) for small to medium sized businesses.
                </p>
              </div>
            </div>
            <div className="col-lg-8  weare_right">
              <div className="container">
                {/* ---- */}
                <div className="row">
                  <div className="col-lg-12 text-center">
                    {/* <video src={require('./images/video1.mp4')} autoPlay muted loop></video> */}
                    <img
                      src={require("./images/android-app-developtment.webp")}
                      width="80%"
                      alt=""
                    />
                  </div>
                </div>
                {/* ---- */}
                <div className="row  d-none">
                  <div className="col-lg-6">
                    <div className="card mb-3" style={{ maxWidth: "540px" }}>
                      <div className="row g-0 justify-content-center align-items-center">
                        <div className="col-md-3">
                          <img
                            src={require("./images/launch.png")}
                            className="img-fluid rounded-start"
                            alt="..."
                          />
                        </div>
                        <div className="col-md-9">
                          <div
                            className="card-body "
                            style={{ textAlign: "justify" }}
                          >
                            <h5 className="card-title">Card title</h5>
                            <em className="card-text">
                              This is a wider card with supporting text below as
                              a natural{" "}
                            </em>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="card mb-3" style={{ maxWidth: "540px" }}>
                      <div className="row g-0 justify-content-center align-items-center">
                        <div className="col-md-3">
                          <img
                            src={require("./images/launch.png")}
                            className="img-fluid rounded-start"
                            alt="..."
                          />
                        </div>
                        <div className="col-md-9">
                          <div
                            className="card-body "
                            style={{ textAlign: "justify" }}
                          >
                            <h5 className="card-title">Card title</h5>
                            <em className="card-text">
                              This is a wider card with supporting text below as
                              a natural{" "}
                            </em>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="card mb-3" style={{ maxWidth: "540px" }}>
                      <div className="row g-0 justify-content-center align-items-center">
                        <div className="col-md-3">
                          <img
                            src={require("./images/launch.png")}
                            className="img-fluid rounded-start"
                            alt="..."
                          />
                        </div>
                        <div className="col-md-9">
                          <div
                            className="card-body "
                            style={{ textAlign: "justify" }}
                          >
                            <h5 className="card-title">Card title</h5>
                            <em className="card-text">
                              This is a wider card with supporting text below as
                              a natural{" "}
                            </em>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="card mb-3" style={{ maxWidth: "540px" }}>
                      <div className="row g-0 justify-content-center align-items-center">
                        <div className="col-md-3">
                          <img
                            src={require("./images/launch.png")}
                            className="img-fluid rounded-start"
                            alt="..."
                          />
                        </div>
                        <div className="col-md-9">
                          <div
                            className="card-body "
                            style={{ textAlign: "justify" }}
                          >
                            <h5 className="card-title">Card title</h5>
                            <em className="card-text">
                              This is a wider card with supporting text below as
                              a natural{" "}
                            </em>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* =========== Who We Are ============== */}

      {/* =========== Who We Work For ============== */}
      <section className="workfor">
        <div className="container py-3 ">
          <div className="text-center py-lg-5">
            <b>WORK FOR ANY INDUSTRY</b>
            <h2>
              <b>Best Solutions, For All Organizations</b>
            </h2>
          </div>
          <div className="row ">
            <div className="col-lg-2 col-6 py-3 ">
              <div
                className="card p-lg-4 p-2 border-0"
                style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
              >
                <div className="text-center ">
                  <img
                    src={require("./images/industry/herbal-treatment.png")}
                    alt="..."
                  />
                </div>
                <div className="card-body text-center px-0">
                  <p className="card-title">
                    <b>Ayurvedic</b>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-6 py-3 ">
              <div
                className="card p-lg-4 p-2 border-0"
                style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
              >
                <div className="text-center ">
                  <img src={require("./images/industry/blog.png")} alt="..." />
                </div>
                <div className="card-body text-center px-0">
                  <p className="card-title">
                    <b>Blogging</b>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-6 py-3 ">
              <div
                className="card p-lg-4 p-2 border-0"
                style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
              >
                <div className="text-center ">
                  <img
                    src={require("./images/industry/online-shop.png")}
                    alt="..."
                  />
                </div>
                <div className="card-body text-center px-0">
                  <p className="card-title">
                    <b>E-Commerce</b>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-6 py-3 ">
              <div
                className="card p-lg-4 p-2 border-0"
                style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
              >
                <div className="text-center ">
                  <img
                    src={require("./images/industry/education.png")}
                    alt="..."
                  />
                </div>
                <div className="card-body text-center px-0">
                  <p className="card-title">
                    <b>Education</b>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-6 py-3 ">
              <div
                className="card p-lg-4 p-2 border-0"
                style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
              >
                <div className="text-center ">
                  <img src={require("./images/industry/dress.png")} alt="..." />
                </div>
                <div className="card-body text-center px-0">
                  <p className="card-title">
                    <b>Fashion</b>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-6 py-3 ">
              <div
                className="card p-lg-4 p-2 border-0"
                style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
              >
                <div className="text-center ">
                  <img
                    src={require("./images/industry/burger.png")}
                    alt="..."
                  />
                </div>
                <div className="card-body text-center px-0">
                  <p className="card-title">
                    <b>Food</b>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-6 py-3 ">
              <div
                className="card p-lg-4 p-2 border-0"
                style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
              >
                <div className="text-center ">
                  <img src={require("./images/industry/gamer.png")} alt="..." />
                </div>
                <div className="card-body text-center px-0">
                  <p className="card-title">
                    <b>Gaming</b>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-6 py-3 ">
              <div
                className="card p-lg-4 p-2 border-0"
                style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
              >
                <div className="text-center ">
                  <img
                    src={require("./images/industry/hospital.png")}
                    alt="..."
                  />
                </div>
                <div className="card-body text-center px-0">
                  <p className="card-title">
                    <b>Hospital</b>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-6 py-3 ">
              <div
                className="card p-lg-4 p-2 border-0"
                style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
              >
                <div className="text-center ">
                  <img src={require("./images/industry/it.png")} alt="..." />
                </div>
                <div className="card-body text-center px-0">
                  <p className="card-title">
                    <b>IT</b>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-6 py-3 ">
              <div
                className="card p-lg-4 p-2 border-0"
                style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
              >
                <div className="text-center ">
                  <img src={require("./images/industry/deal.png")} alt="..." />
                </div>
                <div className="card-body text-center px-0">
                  <p className="card-title">
                    <b>Real Estate</b>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-6 py-3 ">
              <div
                className="card p-lg-4 p-2 border-0"
                style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
              >
                <div className="text-center ">
                  <img
                    src={require("./images/industry/knitting.png")}
                    alt="..."
                  />
                </div>
                <div className="card-body text-center px-0">
                  <p className="card-title">
                    <b>Textiles</b>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-6 py-3 ">
              <div
                className="card p-lg-4 p-2 border-0"
                style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
              >
                <div className="text-center ">
                  <img
                    src={require("./images/industry/airplane.png")}
                    alt="..."
                  />
                </div>
                <div className="card-body text-center px-0">
                  <p className="card-title">
                    <b>Travel</b>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* =========== Who We Work For ============== */}

      {/* =========== Call to Action ============== */}
      <section className="ctaabout py-5">
        <div class="cta1">
          <div className="container py-lg-5 ">
            <div className="text-center text-white mb-4 mb-lg-0">
              {/* <b>WORK FOR ANY INDUSTRY</b> */}
              <b>You have idea, We have solution</b>
              <h2>
                <b>Let's start a new project together</b>
              </h2>
            </div>
            <div className="d-flex justify-content-center">
              <a className="btn mx-3 text-white " href="tel:7988829912">
                Call Now
              </a>
              <Link className="btn mx-3 bg-white text-dark " to="/contact">
                Contact us
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* =========== Call to Action ============== */}

      {/* =========== Technology Stack ============== */}
      <section className="tech_stack  px-2">
        <div className="container py-3 ">
          <div className="text-center py-5">
            <b>TECHNOLOGY INDEX</b>
            <h2>
              <b>
                The Technologies We Are Using <br /> For Our Valued Customers
              </b>
            </h2>
          </div>
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-2 col-4 py-4 text-center">
              <img src={require("./images/techstack/1.png")} alt="" />
            </div>
            <div className="col-lg-2 col-4 py-4 text-center">
              <img src={require("./images/techstack/5.png")} alt="" />
            </div>
            <div className="col-lg-2 col-4 py-4 text-center">
              <img src={require("./images/techstack/atom.png")} alt="" />
            </div>
            <div className="col-lg-2 col-4 py-4 text-center">
              <img src={require("./images/techstack/wordpress.png")} alt="" />
            </div>
            <div className="col-lg-2 col-4 py-4 text-center">
              <img src={require("./images/techstack/shopify.png")} alt="" />
            </div>
            <div className="col-lg-2 col-4 py-4 text-center">
              <img src={require("./images/techstack/3.png")} alt="" />
            </div>
            <div className="col-lg-2 col-4 py-4 text-center">
              <img src={require("./images/techstack/php.png")} alt="" />
            </div>
            <div className="col-lg-2 col-4 py-4 text-center">
              <img src={require("./images/techstack/2.png")} alt="" />
            </div>
            <div className="col-lg-2 col-4 py-4 text-center">
              <img src={require("./images/techstack/nodejs.png")} alt="" />
            </div>
            <div className="col-lg-2 col-4 py-4 text-center">
              <img src={require("./images/techstack/4.png")} alt="" />
            </div>
            <div className="col-lg-2 col-4 py-4 text-center">
              <img src={require("./images/techstack/6.png")} alt="" />
            </div>
            <div className="col-lg-2 col-4 py-4 text-center">
              <img src={require("./images/techstack/atom.png")} alt="" />

              <p className="card-title">
                <b>React Native</b>
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* =========== Brands ============== */}
      {/* <BrandsSlider /> */}
      {/* =========== Brands ============== */}

      {/* =========== Technology Stack ============== */}
      <div className="py-5 spacer d-lg-block d-none"></div>
      {/* =========== Contact Us ============== */}

      <ContactForm classElement="" />

      {/* =========== Contact Us ============== */}

      <Footer />
    </div>
  );
};
