import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { About } from "./Component/About";
// import { Header } from "./Component/Header";
import { Contact } from "./Component/Contact";
import { Home } from "./Component/Home";
import { LandingpageAds } from "./Component/LandingpageAds";
import { Project } from "./Component/Project";
import { Services } from "./Component/Services";
import TermsConditions from "./Component/TermsConditions";
import PrivacyPolicy from "./Component/PrivacyPolicy";
// import makePayment from "./Component/paytm-button/MakePayment";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<Services />} />
          <Route path="/projects" element={<Project />} />
          <Route path="/terms-and-conditions" element={<TermsConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />

          <Route
            path="/websitedesign-company-in-noida"
            element={<LandingpageAds />}
          />
        </Routes>
      </BrowserRouter>

      {/* <button onClick={makePayment}>Pay Now</button> */}
    </div>
  );
}

export default App;
